<template lang="pug">
  v-app
    .d-flex.h-100(:class="mobileView ? 'flex-column-reverse' : 'flex-row'" style="justify-content: center")
      .d-flex.flex-column.image2.align-items-center 
        img(src="@/assets/images/quiz/jom_quiz_1.png")
        b(class="label-1" v-html="$t('quiz.text')")
        //- b(class="label-3") AND
        //- span(class="label-2" style="color: red") *
        //-   em(class="label-2" v-html="$t('quiz.label')")
        v-btn(
          @click="goQuiz"
          color="primary"
        )
          b {{ $t("quiz.start.text") }}
      img.image(
        src="@/assets/images/quiz/jom_quiz_2.png"
      )
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";

export default {
  computed: {
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    }
  },
  data() {
    return {
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  methods: {
    goQuiz() {
      if (!this.$store.getters.isLoggedIn)
        this.$swal
          .fire({
            title: this.$t("auth.login_required"),
            text: this.$t("auth.login_or_register"),
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#DB258F",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("auth.go"),
            cancelButtonText: this.$t("auth.close")
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$router.push({
                name: "Register"
              });
            }
          });
      else
        this.$router.push({
          name: "QuizMain"
        });
    }
  }
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'md-and-up')}
  .v-application
    height: 82vh !important
    overflow: hidden !important

.label-1
  display: flex
  text-align: center
  margin: 0
  margin-top: 3rem
  align-items: center
  justify-content: center
  font-size: 20px
  color: $secondary-color
  font-weight: 500 !important
  font-family: 'Bw Modelica Medium' !important

.label-2
  margin-top: 1rem
  font-weight: 500 !important
  font-family: 'Bw Modelica' !important
  font-style: italic
  font-size: 18px
  color: $secondary-color

.label-3
  margin-top: 1rem
  margin-bottom: 1rem
  font-weight: 700 !important
  font-family: 'Bw Modelica' !important
  font-size: 24px
  color: black

.v-btn
  margin-top: 3rem
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    margin-bottom: 50px

.image, .image2
  margin: auto 1rem
  width: 35%
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    margin: auto
    width: 80%

.image2
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    margin-top: 50px
</style>
